import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isDataReady: false,
        otherLocalesUrls: {},
        seo: {
            title: null,
            titleRaw: {},
            description: null,
            descriptionRaw: '',
            keywords: [],
            score: '',
            social: {
                twitter: {
                    handle: '',
                    title: '',
                    imageId: '',
                    description: '',
                },
                facebook: {
                    handle: '',
                    title: '',
                    imageId: '',
                    description: '',
                },
            },
            advanced: {
                robots: [],
                canonical: '',
            },
        },
        form: {
            loading: null,
            sent: false,
            error: false,
        },
    },
    mutations: {
        ['SET_DATA_READY'] (state, status) {
            state.isDataReady = status;
        },
        ['SET_OTHER_LOCALES_URLS'] (state, urls) {
            state.otherLocalesUrls = urls;
        },
        ['SET_SEO'] (state, seo) {
            state.seo = { ...seo, description: seo.descriptionRaw['1'], title: seo.titleRaw['1'] }
        },
        ['FORM_SENDING'](state, payload) {
            state.form.loading = payload
        },

        ['FORM_SENT'](state, payload) {
            state.form.sent = payload
        },

        ['FORM_ERROR'](state, payload) {
            state.form.error = payload
        },
    },
    actions: {
        async sendContactForm({ commit }, formData) {
            commit('FORM_SENDING', true)
            try {
                const { data } = await Vue.prototype.$craft.sendContactFormData(
                    formData,
                    Vue.prototype.$csrfToken.csrfTokenValue
                )
                if (data && !data?.errors) {
                    commit('FORM_SENT', true)
                    return data
                }
                throw new Error('form not sent')
            } catch (error) {
                commit('FORM_SENT', false)
                commit('FORM_ERROR', true)
                console.error(error)
            } finally {
                commit('FORM_SENDING', false)
            }
        },
    },
    getters: {
        isDataReady: (state) => state.isDataReady,
        otherLocalesUrls: (state) => state.otherLocalesUrls,
        seo: state => state.seo,

        // FORMS
        formSending: state => state.form.loading,
        formSent: state => state.form.sent,
        formError: state => state.form.error,
    },
});
