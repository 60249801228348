<template>
    <section class="prefooter appear" v-if="content">

        <img class="mandala big" src="@/assets/images/mandala-newsletter.png" data-appear="a4"/>

        <div class="intro">
            <span class="big" data-appear="a1">{{$t('prefooter.title')}}</span>
            <span data-appear="a2">{{$t('prefooter.text')}}</span>
        </div>
        <div class="container">
            <div class="inner">
                <img class="left" src="@/assets/images/element-bg-g.svg" />
                <img class="right" src="@/assets/images/element-bg-d.svg" />
                <ul>
                    <li v-for="(partner, p) in this.content" :key="p" :class="{'not-major': partner.partner_category['value'] !== 'major_partners'}">
                        <template v-if="partner.partner_category['value'] === 'major_partners'">
                            <div class="partner">
                                <a :href="partner.partner_link" target="_blank" v-if="partner.partner_link !== ''">
                                    <img :src="partner.partner_logo"  data-appear="a3" />
                                </a>
                                <img :src="partner.partner_logo" v-else/>
                            </div>
                        </template>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>

    export default {
        name: "PreFooter",
        components: {},
        data() {
            return {
                content: null,
            }
        },
        created() {
            // Fetch the page's specific content
            this.$craft.getContentBySlug(this.$t('nav.partners.slug'))
                .then(res => {
                    this.content = res.data.partners;
                })
        },

        mounted() {

        }
    }
</script>

<style scoped>

</style>
