<template>
    <div id="app">

        <header>
            <MainMenu
                :navItems="navItems"
                :socialLinks="socialLinks"
                :extraPages="extraPages"
                v-if="navItems && socialLinks && extraPages"
            />
        </header>

        <router-view :key="$route.fullPath"/>

        <transition name="quick-fade">
            <Loader v-if="!isDataReady"/>
        </transition>


        <PreFooter/>

        <Footer
            :socialLinks="socialLinks"
            :extraPages="extraPages"
            v-if="socialLinks && extraPages"
        />
    </div>
</template>


<script>
    // @ is an alias to /src
    import { mapGetters } from 'vuex';
    import Footer from "./components/layout/Footer";
    import PreFooter from "./components/layout/PreFooter";
    import Loader from "./components/parts/Loader";
    import MainMenu from "./components/parts/MainMenu";

    const body = document.getElementsByTagName('body')[0];

    export default {
        name: 'Home',
        components: {
            MainMenu,
            PreFooter,
            Footer,
            Loader
        },

        data() {
            return {
                content: null,
                socialLinks: null,
                extraPages: null,
                navItems: null,
            }
        },

        created() {

                this.$craft.getMainNavigation()
                    .then(res => {
                        this.navItems = res.data.data[0].mainNav;
                    });

                this.$craft.getContactInformation()
                    .then(res => {
                        this.socialLinks = res.data.socialMedias;
                    });

                this.$craft.getFooterNavigation()
                    .then(res => {
                        this.extraPages = res.data.data[0].footer;
                    });



        },
        mounted() {
            this.$nextTick(() => {
                this.collapseHeaderNative();
            });
        },
        computed: {
            ...mapGetters(['seo']),

            isDataReady() {
                return this.$store.state.isDataReady;
            },
        },

        metaInfo() {
            return {
                ...(this.seo.title && { title: this.seo.title }),
                ...(this.seo.title && { titleTemplate: '%s | Festival du monde Arabe' }),

                meta: [
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.seo.title,
                    },

                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.seo.description,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.seo.description,
                    },
                ],
                link: [
                    {
                        vmid: 'canonical',
                        rel: 'canonical',
                        href: window.location.href,
                    },
                ],
            }
        },

        methods: {
            // Displays/hides header main nav when scrolling
            collapseHeaderNative() {
                let lastScrollTop;
                const header = document.querySelector('header');

                window.addEventListener('scroll', () => {
                    const st = window.scrollY;
                    if (st <= 300) {
                        // Normal state top of page
                        header.classList.remove('fixed');
                        header.classList.remove('collapsed');
                    } else {
                        if (lastScrollTop <= st && st >= 301) {
                            // Scroll down state
                            header.classList.remove('collapsed');
                            header.classList.add('fixed');
                        }
                        if (lastScrollTop >= st && st >= 400) {
                            // Scroll up state
                            header.classList.add('collapsed');
                        }

                    }
                    lastScrollTop = st <= 0 ? 0 : st;
                });
            }
        }

    };
</script>

<style lang="scss">
    @import '@/assets/styles/main.scss';
</style>
