import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import i18n from "@/i18n";

Vue.use(VueRouter);

const routes = [
    // Accueil
    {
        path: "/",
        component: () => import(/* webpackChunkName: "accueil" */ "../views/Home.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en",
        component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
        meta: { lang: "en" },
    },

    // Page - Listing des spectacles
    {
        path: "/programmation",
        component: () => import(/* webpackChunkName: "programmation" */ "../views/Events.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/program/",
        component: () => import(/* webpackChunkName: "program" */ "../views/Events.vue"),
        meta: { lang: "en" },
    },
    // Page - Listing des Créations
    {
        path: "/creations",
        component: () => import(/* webpackChunkName: "creations" */ "../views/Creations.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/creations",
        component: () => import(/* webpackChunkName: "creations" */ "../views/Creations.vue"),
        meta: { lang: "en" },
    },

    // Page - Listing des Éditions - FR
    {
        path: "/editions",
        component: () => import(/* webpackChunkName: "editions" */ "../views/Editions.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/editions",
        component: () => import(/* webpackChunkName: "editions" */ "../views/Editions.vue"),
        meta: { lang: "en" },
    },

    // Page - Listing des Arabitudes
    {
        path: "/arabitudes",
        component: () => import(/* webpackChunkName: "arabitudes" */ "../views/Arabitudes.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/arabitudes",
        component: () => import(/* webpackChunkName: "arabitudes" */ "../views/Arabitudes.vue"),
        meta: { lang: "en" },
    },

    // Page - Contact
    {
        path: "/contact",
        component: () => import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/contact",
        component: () => import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
        meta: { lang: "en" },
    },

    // Page - Partners
    {
        path: "/partenaires",
        component: () => import(/* webpackChunkName: "partenaires" */ "../views/Partners.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/partners",
        component: () => import(/* webpackChunkName: "partners" */ "../views/Partners.vue"),
        meta: { lang: "en" },
    },

    // Page - About
    {
        path: "/a-propos",
        component: () => import(/* webpackChunkName: "a-propos" */ "../views/About.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/about",
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
        meta: { lang: "en" },
    },

    // Page - Team
    {
        path: "/equipe",
        component: () => import(/* webpackChunkName: "equipe" */ "../views/Team.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/team",
        component: () => import(/* webpackChunkName: "team" */ "../views/Team.vue"),
        meta: { lang: "en" },
    },

    // Page - Medias
    {
        path: "/medias",
        component: () => import(/* webpackChunkName: "medias" */ "../views/Medias.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/medias",
        component: () => import(/* webpackChunkName: "medias" */ "../views/Medias.vue"),
        meta: { lang: "en" },
    },

    // Page - Legal notice
    {
        path: "/mentions-legales",
        component: () =>
            import(/* webpackChunkName: "mentions-legales" */ "../views/LegalNotice.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/legal-notice",
        component: () => import(/* webpackChunkName: "legal-notice" */ "../views/LegalNotice.vue"),
        meta: { lang: "en" },
    },

    // Page - Sales conditions
    {
        path: "/conditions-de-vente",
        component: () =>
            import(/* webpackChunkName: "conditions-de-vente" */ "../views/SalesConditions.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/sales-conditions",
        component: () =>
            import(/* webpackChunkName: "sales-conditions" */ "../views/SalesConditions.vue"),
        meta: { lang: "en" },
    },

    // Single - Evenement / spectacle
    {
        path: "/spectacle/:slug",
        component: () => import(/* webpackChunkName: "evenement" */ "../views/Event.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/show/:slug",
        component: () => import(/* webpackChunkName: "event" */ "../views/Event.vue"),
        meta: { lang: "en" },
    },

    // Single - Création
    {
        path: "/creation/:slug",
        component: () => import(/* webpackChunkName: "creation" */ "../views/Event.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/creation/:slug",
        component: () => import(/* webpackChunkName: "creation" */ "../views/Event.vue"),
        meta: { lang: "en" },
    },

    // Single - Arabitude
    {
        path: "/arabitude/:slug",
        component: () => import(/* webpackChunkName: "arabitude" */ "../views/Arabitude.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/arabitude/:slug",
        component: () => import(/* webpackChunkName: "arabitude" */ "../views/Arabitude.vue"),
        meta: { lang: "en" },
    },
    // Single - Nouvelles
    {
        path: "/nouvelles/:slug",
        component: () => import(/* webpackChunkName: "arabitude" */ "../views/Nouvelle.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/news/:slug",
        component: () => import(/* webpackChunkName: "arabitude" */ "../views/Nouvelle.vue"),
        meta: { lang: "en" },
    },

    // Single - Édition
    {
        path: "/edition/:slug",
        component: () => import(/* webpackChunkName: "edition" */ "../views/Edition.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/edition/:slug",
        component: () => import(/* webpackChunkName: "edition" */ "../views/Edition.vue"),
        meta: { lang: "en" },
    },

    // PAGE - TESTS
    {
        path: "/tests",
        component: () => import(/* webpackChunkName: "tests" */ "../views/Tests.vue"),
        meta: { lang: "fr" },
    },
    {
        path: "/en/tests",
        component: () => import(/* webpackChunkName: "tests" */ "../views/Tests.vue"),
        meta: { lang: "en" },
    },

    // 404
    { path: "/en/:pathMatch(.*)*", redirect: "/en" },
    { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 });
            }, 200);
        });
    },
});

router.beforeEach(async (to, from, next) => {
    store.commit("SET_DATA_READY", false);
    const { lang } = to.meta;
    document.documentElement.lang = lang;
    i18n.locale = lang;

    setTimeout(() => {
        next();
    }, 250); // 250 is time of page loader fade-in
});

export default router;
