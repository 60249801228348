<template>
    <footer class="">
        <div class="left">
            <div class="container fullwidth">
                <div class="inner">
                    <div class="extra_pages" v-if="extraPages">
                        <div class="container fullwidth">
                            <div class="inner">
                                <ul>
                                    <li
                                        data-appear="a1"
                                        v-for="(extraPage, i) in extraPages[0].links"
                                        :key="i"
                                    >
                                        <router-link
                                            :to="getURL(extraPage.url)"
                                            :title="extraPage.title"
                                        >
                                            <span>{{ extraPage.title }}</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="social_links" v-if="socialLinks" data-appear="a2">
                        <div class="container fullwidth">
                            <div class="inner">
                                <ul>
                                    <li v-for="(socialLink, i) in socialLinks" :key="i">
                                        <a :href="socialLink.url" target="_blank"
                                            ><span
                                                :class="(`icon-` + socialLink.label) | cleanString"
                                            ></span
                                        ></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="legal" data-appear="a3">
                        <span class="copyright">{{ $t("footer.copyright") }}</span>

                        <div class="madeby" v-if="false">
                            <span>{{ $t("footer.madeby") }}</span
                            ><a
                                :href="`https://leeroy.ca/${
                                    this.$i18n.locale === 'fr' ? '' : 'en'
                                }`"
                                target="_blank"
                                ><img src="@/assets/images/leeroy-logo.svg"
                            /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right">
            <img class="mandala" src="@/assets/images/mandala-footer.png" data-appear="a3" />
            <div class="container fullwidth">
                <div class="inner">
                    <div class="newsletter">
                        <form
                            method="get"
                            accept-charset="UTF-8"
                            name="oi_form"
                            :action="$t('footer.newsletterLink')"
                            target="_blank"
                        >
                            <span class="title" data-appear="a1">{{
                                $t("footer.newsletterTitle")
                            }}</span>
                            <div class="field" data-appear="a2">
                                <input
                                    type="email"
                                    name="email"
                                    :placeholder="$t('footer.newsletterPlaceholder')"
                                />
                                <input type="hidden" name="goto" value="" />
                                <input type="hidden" name="iehack" value="&#9760;" />
                                <div class="submit">
                                    <input type="submit" name="subscribe" value="" />
                                    <img src="@/assets/images/arrow-cta.svg" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    components: {},
    data() {
        return {};
    },
    props: {
        socialLinks: {
            type: Array,
            default: () => [],
        },
        extraPages: {
            type: Array,
            default: () => [],
        },
    },
    created() {},

    mounted() {},
    methods: {
        getURL(url) {
            if (this.$i18n.locale === "fr") {
                return `/${url}`;
            }
            return url;
        },
    },
    filters: {
        cleanString: function (value) {
            if (!value) return "";

            return value.trim().toLowerCase();
        },
    },
};
</script>

<style scoped></style>
